import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import { SEO, SiteMetadata } from '../components/seo/seo';
import { Grid, Typography, Avatar, Hidden } from '@material-ui/core';
import { Schedule, EventAvailable, KeyboardArrowDownRounded } from '@material-ui/icons';
import { DefaultHeader } from '../components/Header/Header';
import { makeStyles } from '@material-ui/core/styles';
import { postStyle } from './postStyle';
import { Parallax } from '../components/Parallax/Parallax';
import { DefaultFooter } from '../components/Footer/Footer';
import { Link as GatsbyLink } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import { Button } from '../components/Button/Button';
import { primaryColor, warningColor } from '../styles/materialKitBase';

type PostTemplateProps = PageProps<
  {
    site: SiteMetadata;
    blogPost: {
      frontmatter: {
        coverImage: {
          childImageSharp: {
            fixed: FixedObject;
          };
        };
        title: string;
        series: string | null;
        date: string;
        slug: string;
        datePretty: string;
        author?: string;
        authorAvatar?: {
          childImageSharp: {
            fixed: FixedObject;
          };
        };
      };
      html: string;
      excerpt: string;
      timeToRead: number;
    };
  },
  { postId: string; slug: string }
>;

const useStyles = makeStyles(postStyle);

// TODO: check more https://github.com/nehalist/gatsby-theme-nehalem/blob/master/theme/src/templates/post.tsx
const PostTemplate: FC<PostTemplateProps> = ({ data }) => {
  const { blogPost, site } = data;
  const classes = useStyles();
  const image = blogPost.frontmatter.coverImage.childImageSharp.fixed.src;

  return (
    <div style={{ background: 'linear-gradient(#212121, #414345)' }}>
      <SEO
        title={blogPost.frontmatter.title}
        slug={blogPost.frontmatter.slug}
        description={blogPost.excerpt}
        featuredImage={image}
      />
      <DefaultHeader />

      <Parallax image={image} className={classes.parallax} filter="dark">
        <div className={classes.container}>
          <Grid container justify="center">
            <Hidden xsDown>
              <KeyboardArrowDownRounded
                style={{ color: primaryColor[0], bottom: '70px', position: 'absolute', fontSize: '3.2rem' }}
              />
              <KeyboardArrowDownRounded
                style={{
                  color: warningColor[0],
                  bottom: '83px',
                  position: 'absolute',
                  fontSize: '3.2rem',
                  animation: 'bounce 4s infinite',
                }}
              />
            </Hidden>
            <Grid item md={9} className={classes.textCenter}>
              <Typography component="h1" variant="h3" className={classes.title}>
                {blogPost.frontmatter.title}
              </Typography>
              <Typography
                component="h2"
                variant="subtitle2"
                className={`${classes.cardCategory} ${classes.cardCategoryPost}`}
              >
                <EventAvailable />
                <time dateTime={blogPost.frontmatter.date}>{blogPost.frontmatter.datePretty}</time>
                <span style={{ padding: '0 8px', lineHeight: '1.5rem' }}>|</span>
                <Schedule />
                {blogPost.timeToRead} min read
                <span style={{ padding: '0 8px', lineHeight: '1.5rem' }}>|</span>
                <Avatar
                  src={
                    blogPost.frontmatter.authorAvatar
                      ? blogPost.frontmatter.authorAvatar.childImageSharp.fixed.src
                      : `${site.siteMetadata.siteUrl}${site.siteMetadata.author.avatar}`
                  }
                  alt={blogPost.frontmatter.author ? blogPost.frontmatter.author : site.siteMetadata.author.name}
                  className={classes.avatarSmall}
                />
                {blogPost.frontmatter.author ? blogPost.frontmatter.author : site.siteMetadata.author.name}
              </Typography>
              <br />
              {blogPost.frontmatter.series && (
                <Button color="info" size="lg" round component={GatsbyLink} to={`/${blogPost.frontmatter.series}`}>
                  Explore Other Articles from This Series
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </Parallax>

      <div className={classes.main} style={{ marginBottom: '60px' }}>
        <div className={classes.container}>
          <Grid container justify="center">
            <Grid item xs={12} sm={9} md={9}>
              <article className={classes.section}>
                <div dangerouslySetInnerHTML={{ __html: blogPost.html }} />
              </article>
            </Grid>
          </Grid>
        </div>
      </div>
      <DefaultFooter />
    </div>
  );
};

export default PostTemplate;

export const query = graphql`
  query Post($postId: String!) {
    site {
      siteMetadata {
        siteUrl
        author {
          name
          avatar
        }
      }
    }
    blogPost: markdownRemark(id: { eq: $postId }) {
      frontmatter {
        title
        slug
        series
        date
        datePretty: date(formatString: "DD MMMM, YYYY")
        coverImage {
          childImageSharp {
            fixed(width: 1000) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        author
        authorAvatar {
          childImageSharp {
            fixed(width: 40, height: 40) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      timeToRead
      excerpt(pruneLength: 150)
      html
    }
  }
`;
